import React from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import { useLocation } from 'cccisd-react-router';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const MainLayout = props => {
    const { pathname } = useLocation();

    return (
        <div className={style.wrapper}>
            <div className={style.header}>
                <NavBar className={props.className} />
            </div>
            {!Fortress.auth() && ['/register', '/'].includes(pathname) && (
                <div className={style.registerBanner}>
                    <div className={props.className}>
                        <p>
                            Welcome to Educate-SMART! Educate-SMART (School Mental Health Awareness
                            and Response Training) is a one-stop training portal designed for school
                            staff members, caregivers, and students to learn about trauma-informed
                            behavioral health topics. Educate-SMART aims to increase school staff
                            members&#39;, caregivers&#39;, and students&#39; knowledge about
                            behavioral health, well-being and self-care, and crisis response so that
                            they can better attend to the behavioral health needs of students and
                            peers. Educate-SMART also includes downloadable resources that
                            complement the lessons in the portal. Register to get started!
                        </p>
                    </div>
                </div>
            )}
            <div className={style.body}>
                {props.showSecondaryNav && Fortress.auth() && (
                    <SecondaryNav
                        className={props.className}
                        navs={AppDefs.navs}
                        routes={AppDefs.routes}
                    />
                )}
                <div className={props.className}>{props.children}</div>
            </div>

            <div className={style.footer}>
                <Footer className={props.className} />
            </div>
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    showSecondaryNav: PropTypes.bool,
};

MainLayout.defaultProps = {
    className: 'container',
    showSecondaryNav: false,
};

export default MainLayout;
