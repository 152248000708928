import React from 'react';
import { AssignmentPlanPlayer } from 'cccisd-laravel-assignment';
import Header from '../../components/NavBar';
import Footer from '../../components/Footer';
import style from './style.css';

const Course = () => {
    const renderError = () => (
        <div className={style.errorPage}>
            <Header />
            <div className={style.body}>
                <div className="container">
                    <div className="alert alert-danger">
                        <p>
                            There are no assigned surveys for your role at this time. Please contact
                            your District Adminstrator for support.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );

    return <AssignmentPlanPlayer renderError={renderError} />;
};

export default Course;
