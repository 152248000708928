import React from 'react';
import { Field, CccisdSelect } from 'cccisd-formik';
import { client } from 'cccisd-apollo';
import query from './districtList.graphql';

const categories = window.cccisd.appDefs.pawn.fields[0].values;
const list = categories.map(category => ({ value: category.value, label: category.name }));
const empty = { value: '', label: '-- Select --' };
list.unshift(empty);

export default class Component extends React.Component {
    state = { district: [] };
    componentDidMount() {
        const getDistricts = async () => {
            const response = await client.query({
                query,
                variables: {
                    some: 'variable',
                },
            });
            const districtLists = response.data.groups.siteList;
            const formattedDistrictLists = districtLists.map(districts => ({
                value: districts.group.groupId,
                label: districts.group.label,
            }));
            formattedDistrictLists.unshift(empty);
            this.setState({
                district: formattedDistrictLists,
            });
        };
        getDistricts();
    }

    render() {
        return (
            <>
                <Field
                    name="initialDistrict"
                    component={CccisdSelect}
                    label="District:"
                    options={this.state.district}
                />
                <Field
                    name="traineeCategory"
                    component={CccisdSelect}
                    label="Which best describes your role?"
                    options={list}
                />
            </>
        );
    }
}
