import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import educatelogo from './educate.svg';
import style from './style.css';

const NavBar = ({ className }) => {
    return (
        <Header
            className={className}
            loginLinkLabel="Register | Login"
            loginLinkTo="/register"
            logo={<img src={educatelogo} className={style.logo} alt="Educate-SMART Logo" />}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
