import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

import awareLogo from './aware_logo.png';
import logo from './Logo.png';
import secondlogo from './Logo1.png';
import thirdlogo from './Logo2.png';
import fourthlogo from './Logo3.png';

const hostName = window.location.hostname;

const Footer = props => {
    return (
        <div className={style.footer}>
            <div className={style.topfooter}>
                <div className={props.className}>
                    <p className={style.pictures}>
                        <img src={awareLogo} className={style.awareLogo} alt="AWARE Logo" />
                        <a
                            href="https://www.chdi.org/"
                            className={style.logo3}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={fourthlogo}
                                href="https://www.chdi.org/"
                                className={style.logo3}
                                alt="CHDI Logo"
                            />
                        </a>
                        <img src={secondlogo} className={style.logo1} alt="CSDE Logo" />
                        <img src={thirdlogo} className={style.logo2} alt="DCF Logo" />
                        <img src={logo} className={style.logo} alt="SAMHSA Logo" />
                    </p>
                    <div>
                        Educate SMART training was developed by the Child Health and Development Institute (CHDI) for
                        Connecticut’s Project AWARE initiative.
                    </div>
                </div>
            </div>
            <div className={style.bottomfooter}>
                <div className={props.className}>
                    <div className={style.wrapper}>
                        <div>
                            Website and software developed by{' '}
                            <a
                                className={style.anchor}
                                href={`//www.3cisd.com?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                3C Institute.
                            </a>{' '}
                            &copy; {props.copyrightYear}. All rights reserved.
                        </div>
                        {props.showPolicyLinks && (
                            <div>
                                <a
                                    href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Terms
                                </a>
                                |
                                <a
                                    href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Privacy
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
    copyrightYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
    copyrightYear: new Date().getFullYear(),
};

export default Footer;
